import { CheckCircleOutlined, CloseCircleOutlined, CrownOutlined, GithubOutlined, GoogleOutlined, HistoryOutlined, MailOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, message, Divider, Tooltip, Input, Form, Tag } from 'antd';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormCheckbox } from '@ant-design/pro-form';
import { useModel } from '@umijs/max';
import {
  users,
  addUser,
  delUser,
  updateUser,
  enableUsers,
  inviteUser,
  reinviteUser,
  smtpConfigValidate,
} from '@/services/api';
import type { API } from '@/services/typings';
import type { PwdStrengthLevel } from '../../components/PwdStrength';
import { PwdStrength, getPwdStrength } from '../../components/PwdStrength';
import { t, renderDetails, getTablePaginationConfig } from '../../global';
import styles from './index.less';
import { NameEditor } from '../DeviceList/DevicesTable';
import DeviceSelect from './DeviceSelect'

enum UserStatus {
  Disabled = 0,
  Normal = 1,
  Pending = -1,
}

const handleAdd = async (data: API.UserListItem) => {
  const hide = message.loading(t('Adding'));
  try {
    await addUser({ data });
    hide();
    message.success(t('Added successfully'));
    return true;
  } catch (error) {
    hide();
    message.error(
      typeof error == 'string' ? (error as string) : t('Adding failed, please try again!'),
    );
    return false;
  }
};

const handleUpdate = async (fields: API.UserListItem, old: API.UserListItem) => {
  const data = { guid: old.guid } as any;
  if ((fields.email || '') != (old.email || '')) {
    data['email'] = fields.email;
  }
  if ((fields.note || '') != (old.note || '')) {
    data['note'] = fields.note;
  }
  if ((fields.group_name || '') != (old.group_name || '')) {
    data['grp'] = fields.group_name;
  }
  if (fields.password) {
    data['password'] = fields.password;
  }
  if (!!fields.is_admin != !!old.is_admin) {
    data['is_admin'] = !!fields.is_admin;
  }
  if (Object.keys(data).length == 1) return false;
  const hide = message.loading(t('Updating'));
  try {
    await updateUser({ data });
    hide();

    message.success(t('Update is successful'));
    return true;
  } catch (error) {
    hide();
    message.error(
      typeof error == 'string' ? (error as string) : t('Update failed, please try again!'),
    );
    return false;
  }
};

const handleEnable = async (rows: API.UserListItem[], disable: boolean) => {
  const hide = message.loading(t('Updating'));
  try {
    await enableUsers({
      data: {
        rows: rows.map((x) => x.guid),
        disable,
      },
    });
    hide();

    message.success(t('Update is successful'));
    return true;
  } catch (error) {
    hide();
    message.error(
      typeof error == 'string' ? (error as string) : t('Enable/disable failed, please try again!'),
    );
    return false;
  }
};

export const UserPassword = (props: { required: boolean, autoFocus?: boolean, initialValue?: string }) => {
  const inputRef = useRef<InputRef>(null);
  const [pwdStrength, setPwdStengthLength] = useState<PwdStrengthLevel>(0);
  const [password, setPassword] = useState(props.initialValue ?? '');

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current?.focus();
    }
  }, [props.autoFocus]);

  return (
    <>
      <ProFormText.Password
        initialValue={password}
        rules={[
          {
            required: props.required,
            whitespace: true,
          },
          {
            min: 6,
            max: 300,
          },
        ]}
        width="md"
        name="password"
        label={t(!props.required ? 'New Password' : 'Password', true)}
        help={pwdStrength == 0 ? undefined : <PwdStrength pwdStrength={pwdStrength} />}
        fieldProps={{
          // https://github.com/ant-design/ant-design/issues/10104
          autoComplete: 'new-password',
          onChange: (e) => {
            setPassword(e.target.value);
            setPwdStengthLength(getPwdStrength(e.target.value));
          },
          ref: inputRef,
        }}
      />
      <ProFormText.Password
        initialValue={password}
        rules={[
          {
            required: props.required,
            whitespace: true,
          },
          {
            validator:
              props.required || password.length > 0
                ? (_, value) => {
                  if (!value || value === password) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Confirm password failed, please check your input'),
                  );
                }
                : undefined,
          },
        ]}
        width="md"
        name="confirm-password"
        label={t('Confirm Password', true) as string}
      />
    </>
  );
};

export const EmailEditor = (props: { required?: boolean, disabled?: boolean, onChange?: (v: string) => void }) => {
  return (
    <ProFormText
      fieldProps={{ autoComplete: 'off', onChange: (e: any) => props.onChange?.(e.target.value) }}
      rules={[
        {
          required: props.required,
          whitespace: true,
        },
        {
          max: 300,
        },
        {
          type: 'email',
        },
      ]}
      validateTrigger={['onBlur']}
      width="md"
      name="email"
      label={t('Email', true)}
      readonly={props.disabled}
    />
  );
};

export const UserFields = (props: { isNew: boolean, status?: number, third_auth_type?: string, disabled?: boolean }) => {
  return (
    <>
      <ProFormText
        fieldProps={{ autoComplete: 'off' }}
        rules={[
          {
            required: props.isNew,
            whitespace: true,
          },
          props.isNew ? {
            min: 4,
            max: 30,
          } : {},
        ]}
        width="md"
        name="name"
        readonly={!props.isNew}
        label={t('User Name', true)}
      />
      <ProFormText
        width="md"
        name="third_auth_type"
        hidden={props.isNew || !props.third_auth_type}
        readonly={true}
        label={t('Auth Type', true)}
      />
      {
        props.disabled || props.status === UserStatus.Pending ? null : <UserPassword required={props.isNew} />
      }
      <EmailEditor disabled={props.disabled} />
    </>
  );
};

type DelConfirmFormProps = {
  visible: boolean;
  handleModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  actionRef: React.MutableRefObject<ActionType | undefined>;
  initialValues: API.UserListItem | any;
  del: any;
};

export const DelConfirmForm: React.FC<DelConfirmFormProps> = ({ visible, handleModalVisible, actionRef, initialValues, del }) => {
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const name = initialValues['name'] || initialValues['id'];

  const checkInput = () => {
    const values = form.getFieldsValue();
    if (values.input === name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const onFinish = async () => {
    const hide = message.loading(t('Updating'));
    try {
      await del(initialValues['guid']);
      hide();
      message.info("Successfully updated");
    } catch (err) {
      hide();
      message.error(
        typeof err == 'string' ? (err as string) : t('Deleting failed, please try again!'),
      );
    }
    actionRef.current?.reload();
    handleModalVisible(false);
  };

  return <ModalForm
    form={form}
    title={t('Confirm')}
    visible={visible}
    width="450px"
    initialValues={initialValues}
    onVisibleChange={handleModalVisible}
    submitter={{
      render: () => {
        return (
          <>
            <Button type="primary" size="middle" disabled={buttonDisabled} block htmlType="submit" onClick={onFinish}>
              {t('Delete')}
            </Button>
          </>
        );
      }
    }}>
    <div className={styles.delConfirmText}>
      {t('To confirm')},&nbsp;{t('type')}&nbsp;&quot;<b>{name}</b>&quot;&nbsp;{t("in the box below")}
    </div>
    <ProForm.Item name="input">
      <Input
        onChange={checkInput}
        onPressEnter={onFinish}
      />
    </ProForm.Item>
  </ModalForm>
    ;
}

export const ThirdAuthTypeTag = (auth?: string) => <>
  {auth && <Tooltip title={"Logged in with " + auth + " account"}>
    {auth == "GOOGLE" ? <GoogleOutlined style={{ marginLeft: 6 }} />
      : auth == "GITHUB" ? <GithubOutlined style={{ marginLeft: 6 }} />
        : <Tag style={{ marginLeft: 6 }} color="green">{auth}</Tag>}
  </Tooltip>}
</>;

export const userColumns = (props: { myname: string | undefined, is_strategy?: boolean, onIdClick?: ((entity: API.UserListItem) => void), extraColumns?: ProColumns<API.UserListItem>[] }) => {
  const columns: ProColumns<API.UserListItem>[] = [
    {
      title: t('User Name'),
      dataIndex: 'name',
      render: (dom, record) => {
        const auth = record.third_auth_type;
        return (<>
          {props.onIdClick ? <a
            onClick={() => props.onIdClick?.(record)}
          >
            {dom}
          </a> : <span>{dom}</span>}
          {record.name == props.myname && <Tag style={{ marginLeft: 6 }} color="blue">{t("Me")}</Tag>}
          {ThirdAuthTypeTag(auth)}
        </>
        );
      },
    },
    {
      title: t('Email'),
      dataIndex: 'email',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      hideInSearch: props.is_strategy,
      valueEnum: {
        1: {
          text: t('Normal'),
        },
        0: {
          text: t('Disabled'),
        },
        "-1": {
          text: t('Pending Invite'),
        },
      },
      render: (text, record) => {
        if (record.status == UserStatus.Pending) {
          return (<>
            <Tooltip title={t('Pending Invite')}>
              <HistoryOutlined style={{ color: '#faad14' }} />
            </Tooltip>
            <Tooltip title={t('Resend Invitation')}>
              <MailOutlined style={{ color: '#faad14', marginLeft: 6 }} onClick={async () => {
                const hide = message.loading(t('Updating'));
                try {
                  await reinviteUser({ data: { name: record.name } });
                } catch (error) {
                  message.error(error as any);
                }
                hide();
              }} />
            </Tooltip>
          </>
          );
        } else if (record.status == UserStatus.Disabled) {
          return (
            <Tooltip title={t('Disabled')}>
              <CloseCircleOutlined style={{ color: '#f5222d' }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t('Normal')}>
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            </Tooltip>
          );
        }
      },
    },
    {
      title: t('Role'),
      dataIndex: 'is_admin',
      hideInSearch: props.is_strategy,
      valueEnum: {
        0: {
          text: t('Normal'),
        },
        1: {
          text: t('Administrator'),
        },
      },
      render: (text, record) => {
        if (record.is_admin) {
          return (
            <Tooltip title={t('Administrator')}>
              <CrownOutlined style={{ color: '#faad14' }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={t('Normal')}>
              <UserOutlined style={{ color: '#52c41a' }} />
            </Tooltip>
          );
        }
      },
    },
    {
      title: t('Strategy', true),
      dataIndex: 'strategy_name',
      tooltip: `-: ${t('Default Strategy', true)}`,
    },
    {
      title: t('Group'),
      dataIndex: 'group_name',
      tip: t('groupTip', true, 'Connections within group are allowed') as string,
    },
    {
      title: t('Auth Type'),
      dataIndex: 'third_auth_type',
      hideInTable: true,
      hideInSearch: props.is_strategy,
      valueEnum: {
        "": {
          text: t('Account'),
        },
        "LDAP": {
          text: t('LDAP'),
        },
        "GOOGLE": {
          text: t('Google'),
        },
        "GITHUB": {
          text: t('Github'),
        },
        "OKTA": {
          text: t('Okta'),
        },
        "AZURE": {
          text: t('Azure'),
        },
        "FACEBOOK": {
          text: t('Facebook'),
        },
        "AUTH0": {
          text: t('Auth0'),
        },
        "GITLAB": {
          text: t('GitLab'),
        },
      },
    },
    {
      title: t('Note'),
      dataIndex: 'note',
      valueType: 'textarea',
      search: false,
    },
    ...props.extraColumns || [],
  ];
  return columns;
}

const TableList: React.FC = () => {
  const [createModalVisible, handleCreateModalVisible] = useState<boolean>(false);
  const [inviteModalVisible, handleInviteModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false);
  const [delConfirmModalVisible, handleDelConfirmModalVisible] = useState<boolean>(false);
  const [assignModalVisible, handleAssignModalVisible] = useState<boolean>(false);
  const { initialState } = useModel('@@initialState');

  const [showDetail, setShowDetail] = useState<boolean>(false);

  const actionRef = useRef<ActionType>();
  const [currentRow, setCurrentRow] = useState<API.UserListItem>();
  const [selectedRowsState, setSelectedRows] = useState<API.UserListItem[]>([]);

  const [smtpValid, setSmtpValid] = useState<boolean>(false);

  useEffect(() => {
    if (smtpValid) return;
    (async () => {
      try {
        const res = await smtpConfigValidate();
        setSmtpValid(res);
      } catch (error) {
        setSmtpValid(false);
      }
    })();
  }, [smtpValid]);

  const newOrUpdateForm = (
    isNew: boolean,
    visible: boolean,
    handleModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    pActionRef: React.MutableRefObject<ActionType | undefined>,
    initialValues: API.UserListItem | {},
  ) => {
    if (!visible) return null;
    const isMe = !isNew && currentRow?.name == initialState?.user?.name;
    return (
      <ModalForm
        title={t(isNew ? 'Create' : 'Edit')}
        visible={visible}
        width="400px"
        initialValues={initialValues}
        onVisibleChange={handleModalVisible}
        onFinish={async (value) => {
          const success = await (isNew ? handleAdd : handleUpdate)(
            value as API.UserListItem,
            initialValues as API.UserListItem,
          );
          if (success) {
            handleModalVisible(false);
            if (pActionRef.current) {
              pActionRef.current.reload();
            }
          }
        }}
      >
        <UserFields disabled={isMe} isNew={isNew} status={(initialValues as API.UserListItem).status} third_auth_type={(initialValues as API.UserListItem).third_auth_type} />
        <ProFormCheckbox disabled={isMe} name={["is_admin"]}>{t("Administrator")}</ProFormCheckbox>
        <NameEditor table="grp" field="group_name" label="Group" required />
        <ProFormTextArea
          fieldProps={{ autoComplete: 'off' }}
          width="md"
          name="note"
          rules={[
            {
              max: 300,
            },
          ]}
          label={t('Note', true)}
        />
      </ModalForm>
    );
  };

  const inviteForm = (
    visible: boolean,
    handleModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    pActionRef: React.MutableRefObject<ActionType | undefined>,
    initialValues: API.InviteUser | {},
  ) => {
    if (!visible) return null;
    return (
      <ModalForm
        title={t('Invite')}
        visible={visible}
        width="400px"
        initialValues={initialValues}
        onVisibleChange={handleModalVisible}
        onFinish={async (value) => {
          const hide = message.loading(t('Updating'));
          try {
            await inviteUser({ data: value });
            handleModalVisible(false);
            if (pActionRef.current) {
              pActionRef.current.reload();
            }
          } catch (error) {
            message.error(error as any);
          }
          hide();
        }}
      >
        <ProFormText
          fieldProps={{ autoComplete: 'off' }}
          width="md"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              min: 4,
              max: 30,
            },
          ]}
          label={t('Name', true)}
        />
        <EmailEditor required />
        <NameEditor table="grp" field="group_name" label="Group" required />
        <ProFormTextArea
          fieldProps={{ autoComplete: 'off' }}
          width="md"
          name="note"
          rules={[
            {
              max: 300,
            },
          ]}
          label={t('Note', true)}
        />
      </ModalForm>
    );
  };

  const actionEnable = (record: API.UserListItem,) => {
    return (
      initialState?.user?.name == record.name || record.status == UserStatus.Pending ? null :
        (<>
          <Divider type='vertical' />
          <a
            style={record.status == UserStatus.Normal ? { color: "red" } : {}}
            onClick={async () => {
              setCurrentRow(record);
              await handleEnable([record], record.status == UserStatus.Normal);
              actionRef.current?.reloadAndRest?.();
            }}
          >
            {t(record.status == UserStatus.Normal ? 'Disable' : 'Enable')}
          </a>
        </>)
    );
  };

  const actionDelete = (record: API.UserListItem,) => {
    return (
      initialState?.user?.name == record.name || record.status == UserStatus.Normal ? null :
        (<>
          <Divider type='vertical' />
          <a
            onClick={() => {
              setCurrentRow(record);
              handleDelConfirmModalVisible(true);
            }}
          >
            {t('Delete')}
          </a>
        </>)
    );
  };

  const actionAssign = (record: API.UserListItem,) => {
    return (
      initialState?.user?.is_admin ?
        (<>
          <Divider type='vertical' />
          <a
            onClick={() => {
              setCurrentRow(record);
              handleAssignModalVisible(true);
            }}
          >
            {t('Assign')}
          </a>
        </>) : null
    );
  };

  const columns = userColumns({
    myname: initialState?.user?.name,
    is_strategy: false,
    onIdClick: (entity: API.UserListItem) => {
      setCurrentRow(entity);
      setShowDetail(true);
    },
    extraColumns: [
      {
        title: t('Action'),
        search: false,
        render: (text, record) => (
          <Fragment>
            <a
              onClick={() => {
                setCurrentRow(record);
                handleUpdateModalVisible(true);
              }}
            >
              {t('Edit')}
            </a>
            {actionEnable(record)}
            {actionDelete(record)}
            {actionAssign(record)}
          </Fragment>
        ),
      }]
  });
  return (
    <PageContainer>
      <ProTable<API.UserListItem, API.PageParams>
        headerTitle={t('User List')}
        columnsState={{ persistenceType: 'localStorage', persistenceKey: 'userlist_columns_state' }}
        actionRef={actionRef}
        rowKey="guid"
        search={{
          labelWidth: 120,
        }}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              handleCreateModalVisible(true);
            }}
          >
            <PlusOutlined /> {t('Create')}
          </Button>,
          <Tooltip
            placement="bottom"
            title={smtpValid ? undefined : t('Please configure Email SMTP settings first')}
          >
            <Button
              type="primary"
              key="primary"
              disabled={!smtpValid}
              onClick={() => {
                handleInviteModalVisible(true);
              }}
            >
              <PlusOutlined /> {t('Invite')}
            </Button>
          </Tooltip>,
        ]}
        request={users}
        columns={columns}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        pagination={getTablePaginationConfig("user_list")}
      />
      {selectedRowsState?.length > 0 && (
        <FooterToolbar
          extra={
            <div>
              {t('Chosen')} <a style={{ fontWeight: 600 }}>{selectedRowsState.length}</a>{' '}
              {t('Items')}
            </div>
          }
        >
          {[true, false].map((v) => (
            <Button
              onClick={async () => {
                await handleEnable(selectedRowsState, !v);
                setSelectedRows([]);
                actionRef.current?.reloadAndRest?.();
              }}
            >
              {v ? t('Enable') : t('Disable')}
            </Button>
          ))}
        </FooterToolbar>
      )}
      {newOrUpdateForm(true, createModalVisible, handleCreateModalVisible, actionRef, {})}
      {newOrUpdateForm(
        false,
        updateModalVisible,
        handleUpdateModalVisible,
        actionRef,
        currentRow || {},
      )}
      {inviteForm(inviteModalVisible, handleInviteModalVisible, actionRef, {})}
      {delConfirmModalVisible && <DelConfirmForm visible={delConfirmModalVisible} handleModalVisible={handleDelConfirmModalVisible} actionRef={actionRef} initialValues={currentRow || {}} del={delUser} />}
      {currentRow?.name !== undefined && <DeviceSelect
        visible={assignModalVisible}
        setVisible={handleAssignModalVisible}
        userGuid={currentRow.guid}
        userName={currentRow.name}
      ></DeviceSelect>}
      {renderDetails(
        showDetail,
        currentRow?.guid,
        currentRow,
        columns.slice(0, columns.length - 1),
        setCurrentRow,
        setShowDetail,
      )}
    </PageContainer>
  );
};

export default TableList;
